@import url(//hello.myfonts.net/count/34d296);
@charset "UTF-8";
.bg-gray {
  background-color: #f7f8f9; }

.bg-white {
  background-color: #fff; }

.blue-text {
  color: #3f51b5; }

.text-gray {
  color: #6e7072; }

.text-white {
  color: #fff; }

.full-height {
  height: 100vh; }

.fit-height {
  height: calc(100vh - 40px); }

.full-width {
  width: 100%; }

.minWidth {
  min-width: 350px; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/**
 * @license
 * MyFonts Webfont Build ID 3461782, 2017-10-09T08:34:18-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HarmoniaSansStd-Regular by Monotype
 * URL: https://www.myfonts.com/fonts/mti/harmonia-sans/std-regular-116608/
 *
 * Webfont: HarmoniaSansStd-Bold by Monotype
 * URL: https://www.myfonts.com/fonts/mti/harmonia-sans/std-bold-116608/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3461782
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright 2010 Monotype Imaging Inc. All rights reserved.
 *
 * © 2017 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'HarmoniaSansStd';
  src: url(/static/media/34D296_0_0.b8017509.eot);
  src: url(/static/media/34D296_0_0.b8017509.eot) format("embedded-opentype"), url(/static/media/34D296_0_0.dbb35763.woff2) format("woff2"), url(/static/media/34D296_0_0.41ded5df.woff) format("woff"), url(/static/media/34D296_0_0.f4928d0a.ttf) format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'HarmoniaSansStd';
  src: url(/static/media/34D296_1_0.d306c105.eot);
  src: url(/static/media/34D296_1_0.d306c105.eot) format("embedded-opentype"), url(/static/media/34D296_1_0.78a9958b.woff2) format("woff2"), url(/static/media/34D296_1_0.52bc61f7.woff) format("woff"), url(/static/media/34D296_1_0.878fb865.ttf) format("truetype");
  font-weight: 700;
  font-style: normal; }

a {
  cursor: pointer; }

.cursor-pointer {
  cursor: pointer; }

button {
  font-family: "HarmoniaSansStd", sans-serif !important; }

.border-bottom {
  border-bottom: 1px solid #ccced0; }

.border-top {
  border-top: 1px solid #ecedee; }

.border {
  border: 1px solid #ccced0; }

.normal-text {
  color: #353c43;
  text-decoration: none; }

.big-class-container {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 4px 16px 2px;
  background-color: green;
  color: #fff; }

.medium-class-container {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 16px 1px;
  background-color: green;
  color: #fff; }

.small-class-container {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 8px 1px;
  background-color: green;
  color: #fff;
  margin-left: 8px; }

.editor_container {
  border: 1px solid #f1f1f1;
  padding: 8px;
  max-height: 50vh;
  overflow: auto; }

.MuiPopover-paper {
  min-width: calc(20% + 8px) !important; }

.MuiChip-label {
  font-family: "HarmoniaSansStd", sans-serif !important; }

.MuiInputBase-input,
.MuiListItem-root {
  font-family: "HarmoniaSansStd", sans-serif !important; }

.cursor-pointer {
  cursor: pointer; }

.openseadragon-container {
  display: none; }
  .openseadragon-container:first-child {
    display: block !important; }

div:focus {
  outline: 0; }

.delete-class {
  margin-left: 8px !important;
  margin-bottom: 2px !important; }

.img-fluid {
  max-width: 100%; }

.review-container .editor-class {
  min-height: 440px; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.MuiTabs-scroller  {
  overflow: visible !important; }

.ellipsis-text {
  height: 44px;
  overflow: hidden;
  -webkit-transition: height ease-in-out 300ms;
  transition: height ease-in-out 300ms; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2,
.my-2 {
  margin-top: 1rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.m-3 {
  margin: 2rem !important; }

.mt-3,
.my-3 {
  margin-top: 2rem !important; }

.mr-3,
.mx-3 {
  margin-right: 2rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 2rem !important; }

.ml-3,
.mx-3 {
  margin-left: 2rem !important; }

.m-4 {
  margin: 3rem !important; }

.mt-4,
.my-4 {
  margin-top: 3rem !important; }

.mr-4,
.mx-4 {
  margin-right: 3rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 3rem !important; }

.ml-4,
.mx-4 {
  margin-left: 3rem !important; }

.m-5 {
  margin: 4rem !important; }

.mt-5,
.my-5 {
  margin-top: 4rem !important; }

.mr-5,
.mx-5 {
  margin-right: 4rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 4rem !important; }

.ml-5,
.mx-5 {
  margin-left: 4rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2,
.py-2 {
  padding-top: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1rem !important; }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.p-3 {
  padding: 2rem !important; }

.pt-3,
.py-3 {
  padding-top: 2rem !important; }

.pr-3,
.px-3 {
  padding-right: 2rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 2rem !important; }

.pl-3,
.px-3 {
  padding-left: 2rem !important; }

.p-4 {
  padding: 3rem !important; }

.pt-4,
.py-4 {
  padding-top: 3rem !important; }

.pr-4,
.px-4 {
  padding-right: 3rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 3rem !important; }

.pl-4,
.px-4 {
  padding-left: 3rem !important; }

.p-5 {
  padding: 4rem !important; }

.pt-5,
.py-5 {
  padding-top: 4rem !important; }

.pr-5,
.px-5 {
  padding-right: 4rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 4rem !important; }

.pl-5,
.px-5 {
  padding-left: 4rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.5rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important; }
  .m-sm-2 {
    margin: 1rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important; }
  .m-sm-3 {
    margin: 2rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important; }
  .m-sm-4 {
    margin: 3rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important; }
  .m-sm-5 {
    margin: 4rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.5rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important; }
  .p-sm-2 {
    padding: 1rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important; }
  .p-sm-3 {
    padding: 2rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important; }
  .p-sm-4 {
    padding: 3rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important; }
  .p-sm-5 {
    padding: 4rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .m-md-3 {
    margin: 2rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important; }
  .m-md-4 {
    margin: 3rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important; }
  .m-md-5 {
    margin: 4rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .p-md-3 {
    padding: 2rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important; }
  .p-md-4 {
    padding: 3rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important; }
  .p-md-5 {
    padding: 4rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .m-lg-3 {
    margin: 2rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important; }
  .m-lg-4 {
    margin: 3rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important; }
  .m-lg-5 {
    margin: 4rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .p-lg-3 {
    padding: 2rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important; }
  .p-lg-4 {
    padding: 3rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important; }
  .p-lg-5 {
    padding: 4rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .m-xl-3 {
    margin: 2rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2rem !important; }
  .m-xl-4 {
    margin: 3rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important; }
  .m-xl-5 {
    margin: 4rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .p-xl-3 {
    padding: 2rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2rem !important; }
  .p-xl-4 {
    padding: 3rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important; }
  .p-xl-5 {
    padding: 4rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-2 {
  flex-basis: 0;
  flex-grow: 2;
  max-width: 100%; }

.col-3 {
  flex-basis: 0;
  flex-grow: 3;
  max-width: 100%; }

html {
  font-size: 16px;
  font-family: "HarmoniaSansStd", sans-serif !important;
  color: #353c43; }

h1,
.h1 {
  font-size: 1.625rem;
  margin-bottom: 1.625rem;
  line-height: 2rem;
  font-weight: 700; }

h2,
.h2 {
  font-size: 1.375rem;
  margin-bottom: 1.375rem;
  line-height: 1.75rem;
  font-weight: 700; }

h3,
.h3 {
  font-size: 1.125rem;
  margin-bottom: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700; }

h4,
.h4 {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
  font-weight: 700; }

h5,
.h5 {
  font-size: 0.875rem;
  margin-bottom: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin-top: 0;
  letter-spacing: 0;
  color: #353c43; }

p {
  font-family: "HarmoniaSansStd", sans-serif !important; }

.sm {
  font-size: 0.875rem; }

.xs {
  font-size: 0.75rem; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-bold {
  font-weight: 700; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

body {
  margin: 0;
  padding: 0;
  color: #000;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

.recharts-legend-wrapper .recharts-default-legend .recharts-legend-item {
  cursor: pointer;
  margin-bottom: 7px; }

